import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: false,
    isAutologin: false,
    userId: localStorage.getItem("uid"),
    userName: "",
    ImagePath: "/menuimg",
    MsgCount: 0,
    // pagePath: "http://192.168.29.51:8180/HolyHeart/presi/studentapp/src/php/",
    pagePath: "https://holyheartamritsar.in/php/",
    StudentId: localStorage.getItem("glc"),
    StudentName: localStorage.getItem("StudentName"),
    StudentClass: localStorage.getItem("StudentClass"),
    companyName: localStorage.getItem("companyname"),
  },
  mutations: {},
  actions: {},
  modules: {},
});
