import axios from "axios";
let funs = {};
funs.getPermissions = async function (xuid, xformId) {
  var tbldata = {
    add: false,
    edit: false,
    delete: false,
  };
  await axios
    .post("users.php", {
      optn: "CheckPerm",
      uid: xuid,
      menuid: xformId,
    })
    .then((response) => {
      var resp = response.data.List;
      var data = resp[0];
      if (data.aflg == 1) {
        tbldata.add = true;
      }
      if (data.mflg == 1) {
        tbldata.edit = true;
      }
      if (data.dflg == 1) {
        tbldata.delete = true;
      }
    });

  // tbldata.add=true;
  // tbldata.edit=true;
  // tbldata.delete=true;
  return tbldata;
};

funs.toUpper = (txt) => {
  var ntxt = txt.toUpperCase();
  return ntxt;
};

funs.getdtnow = () => {
  var currentTime = new Date();
  var month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
  var day = currentTime.getDate().toString().padStart(2, "0");
  var year = currentTime.getFullYear();
  var mdt = year + "-" + month + "-" + day;
  return mdt;
};

funs.getbritishdt = () => {
  var currentTime = new Date();
  var month = (currentTime.getMonth() + 1).toString().padStart(2, "0");
  var day = currentTime.getDate().toString().padStart(2, "0");
  var year = currentTime.getFullYear();
  var mdt = day + "/" + month + "/" + year;
  return mdt;
};

funs.gettimenow = () => {
  var currentTime = new Date();
  return currentTime.toTimeString().substring(0, 5);
};

funs.timediff = (xftime, xfdt) => {
  var currdt = funs.getdtnow();
  var curtm = new Date().toTimeString().substring(0, 5);
  var fhrs = new Date(xfdt + " " + xftime).getTime();
  var thrs = new Date(currdt + " " + curtm).getTime();
  var mdiff = (thrs - fhrs) / 1000 / 60 / 60;
  var hrs = parseInt(mdiff);
  var mins = Math.round((mdiff - hrs) * 60, 2);
  var mdays = "";
  if (hrs > 24) {
    mdays = Math.round(hrs / 24) + " Days";
  }
  var tabletime = mdays
    ? mdays
    : hrs > 0
    ? hrs + "Hrs. " + mins + "Min."
    : mins + "Min.";
  return tabletime;
};

funs.checkYN = (eleid) => {
  var txt = document.getElementById(eleid).value;
  txt = funs.toUpper(txt);
  var mytxt = "";
  if (txt != "Y" && txt != "N") {
    mytxt = "N";
  } else {
    mytxt = txt;
  }
  return mytxt;
};

funs.isYN = (eleval) => {
  // var txt = document.getElementById(eleid).value;
  var txt = eleval;
  txt = funs.toUpper(txt);
  var mytxt = "";
  if (txt != "Y" && txt != "N") {
    mytxt = "N";
  } else {
    mytxt = txt;
  }
  return mytxt;
};

funs.inList = (xstr, xarr) => {
  var found = false;
  for (let i = 0; i < xarr.length; i++) {
    const element = xarr[i];
    if (element == xstr) {
      found = true;
      break;
    }
  }
  return found;
};

funs.datediff = (xtoday, xfdt) => {
  // var currdt = funs.getdtnow();
  var currdt = xtoday;
  var curtm = new Date().toTimeString().substring(0, 5);
  var fhrs = new Date(xfdt + " 00:00:00").getTime();
  var thrs = new Date(currdt + " " + curtm).getTime();
  var mdiff = (thrs - fhrs) / 1000 / 60 / 60;
  var hrs = mdiff;
  var mdays = 0;
  if (hrs != 0) {
    mdays = Math.round(hrs / 24);
  }
  return mdays;
};

funs.tobritish = function (xdt) {
  var mdt = xdt;
  var ndt = xdt;
  if (xdt != null || xdt != undefined) {
    var yy = mdt.substr(0, 4);
    var mm = mdt.substr(5, 2);
    var dd = mdt.substr(8, 2);
    ndt = dd + "/" + mm + "/" + yy;
  }
  return ndt;
};

funs.checkEmail = function (xemail) {
  var validemail = false;
  var patr = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  validemail = patr.exec(xemail);
  return validemail;
};

funs.checkPass = function (xpass) {
  var validemail = false;
  if (!xpass.includes(" ")) {
    validemail = true;
  }
  return validemail;
};

funs.cryptdata = function (xval, xoptn) {
  var myarr = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    " ",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "-",
    "/",
    ":",
    ".",
    "?",
    "_",
    "$",
    "*",
    "&",
    "#",
    "!",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    " ",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "-",
    "/",
    ":",
    ".",
    "?",
    "_",
    "$",
    "*",
    "&",
    "#",
    "!",
  ];
  var passkey = "15";
  var maxalpha = parseInt(myarr.length / 2);
  var wordshift = maxalpha % passkey;

  var newtxt = "";
  var txt = xval;
  var newposition = 0;
  for (let i = 0; i < txt.length; i++) {
    var alpha = txt[i];
    var position = myarr.findIndex((row) => {
      return row == alpha;
    });

    if (position < 0) {
      newtxt += alpha;
    } else {
      if (xoptn == "encode") {
        newposition = position + wordshift;
      } else {
        newposition = position - wordshift;
      }
      // console.log("alpha: "+alpha);
      // console.log("pos: "+position);
      // console.log("newpos: "+newposition);
      if (newposition < 0) {
        newtxt += alpha;
      }
      newtxt += myarr[newposition];
    }
  }
  return newtxt;
};

export default funs;
