<!-- @format -->

<template>
  <div>
    <div class="mynavbr">
      <!-- <div class="bar-icon" @click="toggleSideBar()">
        <i class="fa-solid fa-bars-staggered"></i>
      </div> -->
      <div class="title-div">
        <div class="logo-div">
          <img src="../assets/complogo.png" class="topbarlogo" />
        </div>

        <!--Title -->
        <router-link to="/main" class="title">{{
          $store.state.companyName
        }}</router-link>
      </div>

      <div class="logout-div">
        <span class="logout" @click="toggleLogout"> Logout </span>
      </div>
    </div>

    <!-- Logout Box -->
    <logout-box
      v-show="logoutbox"
      @cancel="toggleLogout"
      @logout="logoutBtn"
    ></logout-box>
  </div>
</template>

<script>
import LogoutBox from "./LogoutBox.vue";
export default {
  emits: ["toggleBar"],
  components: {
    LogoutBox,
  },
  data() {
    return {
      companyName: "Holy Heart Schools",
      logoutbox: false,
      userName: this.$store.state.userName,
    };
  },
  methods: {
    toggleLogout() {
      this.logoutbox = !this.logoutbox;
    },
    logoutBtn() {
      localStorage.clear();
      this.$store.state.isAuthenticated = false;
      this.$router.push("/");
    },
    toggleSideBar() {
      this.$emit("toggleBar");
    },
  },
};
</script>
<style>
@import "../css/base.css";
</style>
<style scoped>
.mynavbr {
  position: fixed;
  width: 100%;
  top: 0px;
  display: block;
  box-shadow: 0px 1px 4px 0px lightgray;
  text-align: left;
  padding: 0px;
  z-index: 100;
  background-color: white;
}

.topbarlogo {
  height: 35px;
}
.mynavbr .bar-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  left: 4px;
  top: -8px;
  padding: 6px;
}

.mynavbr .bar-icon:hover {
  border-radius: 5px;
  background-color: lightgray;
}

.mynavbr .title-div,
.logout-div {
  /* width: calc(50% - 20px); */
  display: inline-block;
  position: relative;
  padding: 6px;
}

.mynavbr .logo-div {
  position: relative;
  display: inline-block;
}

.mynavbr .title-div {
  position: relative;
  text-align: left;
  left: 5px;
}

.mynavbr .title-div .title {
  position: relative;
  left: 8px;
  top: -8px;
  text-decoration: none;
  color: var(--theme-color);
  font-size: 22px;
  font-weight: 600;
}

.mynavbr .logout-div {
  position: relative;
  top: 10px;
  float: right;
  text-align: right;
  right: 10px;
}

.logout {
  font-size: 15px;
  font-weight: 700;
  color: slategray;
  cursor: pointer;
}

.logout:hover {
  color: #2c3e50;
}

.username {
  position: relative;
  color: #2c3e50;
  margin-right: 5px;
}

.fa-bars {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mynavbr .bar-icon {
    left: 4px;
    top: -5px;
  }

  .topbarlogo {
    height: 35px;
  }

  .mynavbr .title-div .title {
    top: -8px;
    font-size: 18px;
    font-weight: 600;
    color: var(--theme-color);
  }

  .logout {
    position: relative;
    top: 0px;
    font-size: 12px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 400px) {
  .mynavbr .title-div .title {
    top: -12px;
    font-size: 12px;
    font-weight: bold;
    color: var(--theme-color);
  }
}
</style>
