import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueCryptojs from "vue-cryptojs";
import "animate.css";

// axios.defaults.baseURL =
// "http://192.168.29.51:8180/HolyHeart/presi/studentapp/src/php";
axios.defaults.baseURL = "https://holyheartamritsar.in/php";

// Golbal Components
import MandatoryIcon from "./components/MandatoryIcon.vue";
import AlertBox from "./components/AlertBox.vue";
import CardLoader from "./components/CardLoader.vue";
import CloseBtn from "@/components/CloseBtn.vue";
import GoBack from "@/components/GoBack.vue";
import ConfirmBox from "@/components/ConfirmBox.vue";
import MessageBox from "@/components/MessageBox.vue";

const app = createApp(App);
app.use(VueCryptojs);
app.use(store);
app.use(router);
app.use(router);

app.component("MandatoryIcon", MandatoryIcon);
app.component("AlertBox", AlertBox);
app.component("CardLoader", CardLoader);
app.component("CloseBtn", CloseBtn);
app.component("GoBack", GoBack);
app.component("ConfirmBox", ConfirmBox);
app.component("MessageBox", MessageBox);
app.mount("#app");
